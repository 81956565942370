/* eslint-disable complexity */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useMediaQuery } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { dateOptions, durationOption, recurringOption } from '@src/lib/constants';
import { formatDateRecuring, getRecurringDateRanges, getRecurringDates } from '@src/lib/helper';
import { FontSizes } from '@src/lib/styles/theme';

import { RenderCardValue, RenderChipComponent } from './RenderChipComponent';
import {
  DateBox,
  dateCalendarStyled,
  DateContainer,
  GridContainer,
  Heading,
  StyledChip,
  StyledDateRange,
} from './style';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CalendarProps {
  handleSelect: (key: string, value: string) => void;
  selectedDate: string;
  setSelectedDatePref: Dispatch<SetStateAction<string>>;
  selectedDatePref: string;
  setDateRange: Dispatch<SetStateAction<Range[]>>;
  dateRange: Range[];
  recurringData: Record<string, string>;
  bookingDates: string[];
  setBookingDates: Dispatch<SetStateAction<string[]>>;
  setRecurringData: Dispatch<SetStateAction<Record<string, string>>>;
  scrollToBottom: (top?: number) => void;
}

export const Calendar = ({
  handleSelect,
  selectedDate,
  selectedDatePref,
  dateRange,
  setDateRange,
  setSelectedDatePref,
  recurringData,
  setRecurringData,
  bookingDates,
  setBookingDates,
  scrollToBottom,
}: CalendarProps) => {
  const [selectedNewDate, setSelectedDate] = useState<any>(null);
  const [bookingDatesList, setBookingDatesList] = useState<string[]>([]);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMultipleDays = ['multipleDays', 'multipleDaysMultiRoom'].includes(selectedDatePref);
  const isRecurring = ['singleRecurringDays', 'multipleRecurringDays'].includes(selectedDatePref);

  const getDaySuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th'; // Covers 11th to 19th

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const formatDate = (date: string): string => {
    const parsedDate = dayjs(date);
    const day = parsedDate.date();
    const suffix = getDaySuffix(day);
    return `${parsedDate.format('ddd')} ${day}${suffix}`;
  };

  useEffect(() => {
    if (!isRecurring && selectedNewDate && selectedDatePref && !isMultipleDays) {
      handleSelect('selectedDate', selectedNewDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatePref, selectedNewDate, dateRange, isMultipleDays, isRecurring]);

  useEffect(() => {
    if (selectedNewDate && recurringData.frequency && recurringData.duration) {
      const { startDate, endDate } = dateRange[0];
      let datesData = [];

      if (startDate && endDate && selectedDatePref === 'multipleRecurringDays') {
        datesData = getRecurringDateRanges(
          startDate.toString(),
          endDate.toString(),
          recurringData.frequency,
          recurringData.duration
        );
      } else {
        datesData = getRecurringDates(selectedNewDate, recurringData.frequency, recurringData.duration);
      }

      if (datesData?.length) {
        setTimeout(() => {
          scrollToBottom(datesData.length > 9 ? 440 : 2000);
        }, 100);
      }

      setBookingDatesList(datesData);
      setBookingDates(datesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNewDate, recurringData.frequency, recurringData.duration]);

  const handleDateRangeChange = (newValue: Range) => {
    setDateRange([newValue]);
    setSelectedDate(newValue?.startDate);
  };

  const handleSingleDateChange = (newValue: any) => {
    setSelectedDate(newValue);

    if (!isRecurring && selectedDatePref) {
      handleSelect('selectedDate', newValue || '');
    }
  };

  const handleSelectReruccingData = (key: string, value: string) => {
    setRecurringData(prev => ({ ...prev, [key]: value }));
    scrollToBottom(2000);
  };

  const renderDateOptions = () => {
    return dateOptions.map(({ id, name, isDisable, secondName, $size }) => (
      <StyledChip
        key={id}
        $isPadding
        onClick={() => {
          if (!isDisable) {
            setSelectedDatePref(id);

            if (selectedNewDate && selectedDatePref && !['multipleDays', 'multipleDaysMultiRoom'].includes(id)) {
              handleSelect('selectedDate', selectedNewDate);
            }
          }
        }}
        {...{ $size: $size as FontSizes }}
        $isDisable={isDisable}
        $isSelected={selectedDatePref === id}
        $isLocation
        style={{
          flexDirection: isDesktop ? 'row' : 'column',
          flexWrap: 'wrap',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {name}
        {secondName && <span style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}>{secondName}</span>}
      </StyledChip>
    ));
  };

  useEffect(() => {
    if (isRecurring && selectedNewDate) {
      if (selectedDatePref !== 'multipleRecurringDays') {
        scrollToBottom(10000);
      } else if (dateRange?.[0]?.startDate?.getTime() !== dateRange?.[0]?.endDate?.getTime()) {
        scrollToBottom(10000);
      }
    }
  });

  return (
    <div>
      <div>
        {isMultipleDays || selectedDatePref === 'multipleRecurringDays' ? (
          <div
            style={{
              position: 'relative',
              padding: '0 10px',
              border: '3px solid purple',
              marginBottom: '20px',
              borderRadius: '0 0 8px 8px',
            }}
          >
            <StyledDateRange
              dateDisplayFormat='eee do'
              ranges={dateRange}
              $isDesktop={isDesktop}
              onChange={({ selection }) => handleDateRangeChange(selection)}
              className='custom-style-range'
            />
            <DateContainer>
              <DateBox $isDesktop={isDesktop}>
                <div>START</div>
                {formatDate(dateRange[0]?.startDate?.toString() || '')}
              </DateBox>
              <DateBox $isDesktop={isDesktop}>
                <div>STOP</div>
                {formatDate(dateRange[0]?.endDate?.toString() || '')}
              </DateBox>
            </DateContainer>
            <div
              style={{
                fontSize: isDesktop ? '20px' : '15px',
                fontWeight: '600',
                color: 'purple',
                padding: '0 10px',
                position: 'absolute',
                bottom: '-10px',
                right: '20px',
                background: 'white',
              }}
            >
              Step 1
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                views={['day']}
                value={selectedNewDate ? dayjs(selectedNewDate) : selectedDate ? dayjs(selectedDate) : null}
                sx={dateCalendarStyled(isDesktop)}
                onChange={handleSingleDateChange}
              />
            </LocalizationProvider>
            <div
              style={{
                fontSize: isDesktop ? '20px' : '15px',
                fontWeight: '600',
                color: 'purple',
                padding: '0 10px',
                position: 'absolute',
                bottom: '13px',
                right: '20px',
                background: 'white',
              }}
            >
              Step 1
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          position: 'relative',
          border: '3px solid #007fff',
          marginBottom: '40px',
          padding: '10px',
          borderRadius: '8px',
        }}
      >
        {isMultipleDays && selectedNewDate ? (
          <div style={{ gridColumn: '1/-1' }}>
            <StyledChip
              $isSelected
              $size='l'
              onClick={() => {
                const { startDate, endDate } = dateRange[0];

                if (startDate && endDate && startDate.toString() !== endDate.toString()) {
                  handleSelect('selectedDate', startDate.toString());
                }
              }}
            >
              SELECT THESE DAYS
            </StyledChip>
          </div>
        ) : null}
        {isRecurring && selectedNewDate ? (
          <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
            <StyledChip $isSelected onClick={() => handleSelect('selectedDate', selectedNewDate)}>
              {isMultipleDays ? 'Select Start Stop Dates' : 'select single day'}
            </StyledChip>
            <RenderChipComponent
              values={recurringOption}
              handleSelect={handleSelectReruccingData}
              selectedValues={recurringData}
              heading='Frequency'
              keyName='frequency'
              marginBottom='none'
            />
            <RenderChipComponent
              values={durationOption as RenderCardValue[]}
              handleSelect={handleSelectReruccingData}
              selectedValues={recurringData}
              heading='Duration'
              keyName='duration'
              marginBottom='none'
            />
            {recurringData.frequency && recurringData.duration ? (
              <>
                <Heading
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    userSelect: 'none',
                    textTransform: 'uppercase',
                    marginBottom: '10px',
                  }}
                >
                  Select Any Dates To Remove
                </Heading>
                {bookingDates?.length > 9 ? (
                  <StyledChip
                    style={{ color: 'white', background: 'green', borderColor: 'green', marginBottom: '10px' }}
                    $isLocation
                    $size='xl'
                    onClick={() => handleSelect('selectedDate', selectedNewDate)}
                  >
                    continue
                  </StyledChip>
                ) : null}
                <GridContainer $columnNumber={3} $marginBottom='5px'>
                  {bookingDatesList.map(date => (
                    <StyledChip
                      key={date}
                      $size={'3xs'}
                      style={
                        !bookingDates?.includes(date) ? { background: 'red', borderColor: 'red', color: 'white' } : {}
                      }
                      onClick={() => {
                        setBookingDates(prev =>
                          prev.includes(date) ? prev.filter(value => value !== date) : [...prev, date]
                        );
                      }}
                    >
                      {selectedDatePref === 'singleRecurringDays' ? (
                        formatDateRecuring(date)
                      ) : (
                        <>
                          <div>{formatDateRecuring(date.split('###')[0])}</div>
                          <div>{formatDateRecuring(date.split('###')[1])}</div>
                        </>
                      )}
                    </StyledChip>
                  ))}
                </GridContainer>
                {bookingDates?.length < 10 ? (
                  <StyledChip
                    $isLocation
                    $size='xl'
                    style={{ color: 'white', background: 'green', borderColor: 'green', marginBottom: '10px' }}
                    onClick={() => handleSelect('selectedDate', selectedNewDate)}
                  >
                    continue
                  </StyledChip>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
        {!(isMultipleDays || isRecurring) || !selectedNewDate ? (
          <GridContainer $columnNumber={2} $marginBottom='0px'>
            {renderDateOptions()}
          </GridContainer>
        ) : null}
        <div
          style={{
            fontSize: isDesktop ? '20px' : '15px',
            fontWeight: '600',
            color: '#007fff',
            padding: '0 10px',
            position: 'absolute',
            bottom: '-10px',
            right: '20px',
            background: 'white',
          }}
        >
          Step 2
        </div>
      </div>
    </div>
  );
};
