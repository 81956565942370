const UiRoutes = {
  BOOKING: '/booking',
  LOGIN: '/login',
  VISITOR_HELP: '/visitor-help',
  PARKING_DIRECTION: '/parking-direction',
  MY_BOOKING: '/my-bookings',
  BOOKING_DEMO: '/booking',
  VISITOR_HELP_DEMO: '/visitor-help',
  PARKING_DIRECTION_DEMO: '/parking-direction',
  MY_BOOKING_DEMO: '/my-bookings',
};

export { UiRoutes };

export const roomSizes = [
  { name: '1 person', id: '1 person' },
  { name: '2 persons', id: '2 persons' },
  { name: '4 persons', id: '4 persons' },
  { name: '6 persons', id: '6 persons' },
  { name: '8 persons', id: '8 persons' },
  { name: '10 persons', id: '10 persons' },
  { name: '12 persons', id: '12 persons' },
  { name: '14 persons', id: '14 persons' },
  { name: '20+ persons', id: '20+ persons' },
];

export const mediaTypes = [
  { name: 'none', id: 'none' },
  { name: 'audio-video', id: 'audio-video' },
  { name: 'video-only', id: 'video-only' },
  { name: 'audio-only', id: 'audio-only' },
];

export const time = [
  { name: '7:00 am', id: '7:00am' },
  { name: '8:00 am', id: '8:00am' },
  { name: '9:00 am', id: '9:00am' },
  { name: '10:00 am', id: '10:00am' },
  { name: '11:00 am', id: '11:00am' },
  { name: '12:00 pm', id: '12:00pm' },
  { name: '1:00 pm', id: '1:00pm' },
  { name: '2:00 pm', id: '2:00pm' },
  { name: '3:00 pm', id: '3:00pm' },
  { name: '4:00 pm', id: '4:00pm' },
  { name: '5:00 pm', id: '5:00pm' },
  { name: '6:00 pm', id: '6:00pm' },
];

export const conferenceCities = [
  { name: 'Hotel', id: 'hotel', line2: '1-person' },
  { name: 'Conference', id: 'conference', line2: '2+ persons' },
];

export const servicesOptions = [
  { name: 'Meals', id: 'meals' },
  { name: 'None', id: 'none' },
  { name: 'Beverages', id: 'beverages', subCheckBox: ['Water', 'Soda', ' Iced tea', 'Coffee', 'Ice Bucket'] },
  {
    name: 'IT support',
    id: 'itSupport',
    subCheckBox: [
      'ONLY audio-voice setup for conference call',
      'BOTH audio-voice conference setup AND video-presentation setup',
    ],
  },
];

export const dateOptions = [
  { name: 'Single Day', id: 'singleDaySingleBooking', secondName: 'Single Room' },
  { name: 'Single day', id: 'singleDayMultiBooking', isDisable: false, secondName: 'Multiple rooms' },
  { name: 'Consecutive Days', id: 'multipleDays', isDisable: false, secondName: 'Single Room', $size: 'xs' },
  { name: 'Multiple Days', id: 'multipleDaysMultiRoom', isDisable: false, secondName: 'Multiple Rooms' },
  { name: 'Single Day ', id: 'singleRecurringDays', isDisable: false, secondName: 'Recurring' },
  { name: 'Multiple Days', id: 'multipleRecurringDays', isDisable: false, secondName: 'Recurring' },
];

export const recurringOption = [
  { name: '1-week', id: '1-week' },
  { name: '2-weeks', id: '2-weeks' },
  { name: '3-weeks', id: '3-weeks' },
  { name: '4-weeks', id: '4-weeks' },
  { name: '5-weeks', id: '5-weeks' },
  { name: 'Monthly', id: 'monthly' },
];

export const durationOption = [
  { name: '1-month', id: '1-month' },
  { name: '2-months', id: '2-months' },
  { name: '3-months', id: '3-months' },
  { name: '6-months', id: '6-months' },
  { name: '9-months', id: '9-months' },
  { name: 'End-Of-Year', id: 'endOfYear', size: 'xs' },
];

export const floorsOption = [
  { name: '1 Floor', id: '1' },
  { name: '2 Floor', id: '2' },
  { name: '4 Floor', id: '4' },
  { name: '6 Floor', id: '6' },
  { name: '8 Floor', id: '8' },
  { name: '10 Floor', id: '10' },
  { name: '12+ Floor', id: '12' },
];
